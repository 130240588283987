import { DotIcon, MegaphoneIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Footnote, Header, Sidebar } from "../../components";
import introAudio from "../../assets/audio/intro-to-pwafire.wav";

const Docs = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Build More Capable PWAs & Web Apps";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Nov 22 2024</span>
          </div>
          <h3>Build More Capable PWAs & Web Apps</h3>
          <p style={{ fontSize: "1rem" }}>
            New Web Capabilities as one Package. All APIs are{" "}
            <b>stable in Chrome 80</b> and later versions, also in MS Edge.
          </p>
          <p
            className="quote"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.2rem",
            }}
          >
            <span className="tag-label">
              <MegaphoneIcon />
              <span>NEW</span>
            </span>
            <span style={{ marginLeft: "0.5rem" }}>
              <a href="/compression-streams">Compression Streams API</a> is now
              available, check it out!
            </span>
          </p>

          <figure className="audio-intro">
            <figcaption>
              Listen to a brief introduction to pwafire api and all available
              capabilities
            </figcaption>
            <audio controls src={introAudio} autoPlay></audio>
          </figure>

          <h3>​Install pwafire</h3>
          <p>
            <DotIcon />
            Install <b>latest version</b> of pwafire via npm and start using{" "}
          </p>
          <pre id="copy-code">
            <code className="language-bash">{`  npm i pwafire --save`}</code>
          </pre>
          <p>
            {" "}
            <DotIcon /> Import pwafire in your for e.g React App
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  import { pwa } from "pwafire";`}</code>
          </pre>
          <p>
            <DotIcon />
            Get <b>latest version</b> of pwafire over CDN as an E6 Module{" "}
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  import { pwa } from "https://unpkg.com/pwafire/dist/index.js";`}</code>
          </pre>
          <p>
            <DotIcon />
            Get a <b>specific version</b> of pwafire over CDN as an E6 Module{" "}
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`  import { pwa } from "https://unpkg.com/pwafire@5.1.4/esm/index.js";`}</code>
          </pre>
          <h3>API Specification</h3>
          <p className="sub-title">
            An object response is returned for all APIs
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`// Success...ok value is true...
const res = await pwa.SomeAPI();`}</code>
          </pre>
          <p className="sub-title">
            Do something with the promise value returned for e.g copyText;
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`// Copy text...
const res = await pwa.copyText(text);
// Do something with 'res'...
  if (res.ok) {
    // Success...
  } else {
    // Handle fail...
  }`}</code>
          </pre>
          <section id="detect">
            <div className="flex-box">
              <h3>API Feature Detection</h3>
              <span
                style={{
                  marginLeft: "0.5rem",
                }}
                className="tag-label"
              >
                NEW
              </span>
            </div>
            <p
              style={{
                marginTop: "0",
                paddingTop: "0",
              }}
              className="sub-title"
            >
              Install latest pwafire version
            </p>
            <p>
              <DotIcon /> Goal is to allow room for custom handlers if need be
            </p>
            <p>
              <DotIcon />
              This approach is going to be experimental and will be updated
            </p>
            <p>
              <DotIcon /> This addition is going to be built for available
              stable apis
            </p>
            <p className="sub-title">
              Try it out (here is the{" "}
              <a
                href="https://github.com/pwafire/pwafire/issues/101"
                target="_blank"
                rel="noreferrer"
              >
                explainer
              </a>
              )
            </p>

            <pre id="copy-code">
              <code className="language-javascript">{`// Get the check instance from pwafire...
import { check } from "pwafire";
//...
//...
// The response is a boolean, true or false...
const supported = await check.Share()`}</code>
            </pre>
          </section>
          <section>
            <h3>​Playground</h3>
            <p className="quote">
              {" "}
              Have a look at our demo app to see{" "}
              <a
                href="https://glitch.com/edit/#!/web-pwafire"
                target="_blank"
                rel="noreferrer"
              >
                how it works on
              </a>
              . To learn more about pwafire and for other developer resources,
              go to{" "}
              <a href="https://pwafire.org" target="_blank" rel="noreferrer">
                www.pwafire.org
              </a>
            </p>
          </section>
          <Footnote />
        </section>
      </section>
    </article>
  );
};

export default Docs;
